import React, {lazy} from 'react';
import { createRoot } from 'react-dom/client';

import './i18n'
import {initValidation} from "./validation";
import * as Sentry from "@sentry/react";
import { clarity } from 'react-microsoft-clarity';

const sentryEnabled = (import.meta.env.VITE_SENTRY_ENABLED || "false").toLowerCase() == "true"
const clarityEnabled = (import.meta.env.VITE_MS_CLARITY_ENABLED || "false").toLowerCase() == "true"

if (sentryEnabled) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/childartlab\.com\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    environment: import.meta.env.MODE,
    release: import.meta.env.VITE_VERSION,
  });
}

const loadApp = () => {
  const App = lazy(() => import('./App'))
  const container = document.getElementById('root')
  const root = createRoot(container!)
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  if (clarityEnabled) {
    clarity.init(import.meta.env.VITE_MS_CLARITY_ID)
  }
}

initValidation()
loadApp()
