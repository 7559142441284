import * as yup from "yup";

import type { resources } from "./i18n";


interface LocaleInterface {
  [key: string]: (
    (values: any) => { key: string; values?: any } | string
  ) | string
}

type MixedType = typeof resources.en.validation.mixed
function mixedKey(key: keyof MixedType) {
  return (values: any) => ({ key: `validation:mixed.${key}`, values })
}
const mixed: LocaleInterface = {
  default: mixedKey("default"),
  required: mixedKey("required"),
  defined: mixedKey("defined"),
  notNull: mixedKey("notNull"),
  oneOf: mixedKey("oneOf"),
  notOneOf: mixedKey("notOneOf"),
  notType: ({ type, originalValue }) => {
    if (originalValue == "") return { key: "validation:mixed.required" }
    if (type == "number") return { key: "validation:mixed.wrongNumber" }
    return { key: "validation:mixed.wrongType" }
  },
};

type StringType = typeof resources.en.validation.string
function stringKey(key: keyof StringType) {
  return (values: any) => ({ key: `validation:string.${key}`, values })
}
const string: LocaleInterface = {
  length: stringKey("length"),
  min: stringKey("min"),
  max: stringKey("max"),
  matches: stringKey("matches"),
  email: stringKey("email"),
  url: stringKey("url"),
  uuid: stringKey("uuid"),
  datetime: stringKey("datetime"),
  datetime_precision: stringKey("datetime_precision"),
  datetime_offset: stringKey("datetime_offset"),
  trim: stringKey("trim"),
  lowercase: stringKey("lowercase"),
  uppercase: stringKey("uppercase"),
}

type NumberType = typeof resources.en.validation.number
function numberKey(key: keyof NumberType) {
  return (values: any) => ({ key: `validation:number.${key}`, values })
}
const number: LocaleInterface = {
  min: numberKey("min"),
  max: numberKey("max"),
  lessThan: numberKey("lessThan"),
  moreThan: numberKey("moreThan"),
  positive: numberKey("positive"),
  negative: numberKey("negative"),
  integer: numberKey("integer"),
}

type DateType = typeof resources.en.validation.date
function dateKey(key: keyof DateType) {
  return (values: any) => ({ key: `validation:date.${key}`, values })
}
const date: LocaleInterface = {
  min: dateKey("min"),
  max: dateKey("max"),
}

type BooleanType = typeof resources.en.validation.boolean
function booleanKey(key: keyof BooleanType) {
  return (values: any) => ({ key: `validation:boolean.${key}`, values })
}
const boolean: LocaleInterface = {
  isValue: booleanKey("isValue"),
}

type ObjectType = typeof resources.en.validation.object
function objectKey(key: keyof ObjectType) {
  return (values: any) => ({ key: `validation:object.${key}`, values })
}
const object: LocaleInterface = {
  noUnknown: objectKey("noUnknown"),
}

type ArrayType = typeof resources.en.validation.array
function arrayKey(key: keyof ArrayType) {
  return (values: any) => ({ key: `validation:array.${key}`, values })
}
const array: LocaleInterface = {
  min: arrayKey("min"),
  max: arrayKey("max"),
  length: arrayKey("length"),
}

export function initValidation(): void {
  yup.setLocale({
    mixed,
    string,
    number,
    date,
    boolean,
    object,
    array,
  })
}
