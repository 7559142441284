import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'

import authEn from './locales/en/auth.json'
import commonEn from './locales/en/common.json'
import validationEn from './locales/en/validation.json'
import drawingsEn from './locales/en/drawings.json'
import kidsEn from './locales/en/kids.json'
import settingsEn from './locales/en/settings.json'
import authUa from './locales/ua/auth.json'
import commonUa from './locales/ua/common.json'
import validationUa from './locales/ua/validation.json'
import drawingsUa from './locales/ua/drawings.json'
import kidsUa from './locales/ua/kids.json'
import settingsUa from './locales/ua/settings.json'


export const defaultNS = "common"
export const resources = {
  en: {
    common: commonEn,
    validation: validationEn,
    auth: authEn,
    drawings: drawingsEn,
    kids: kidsEn,
    settings: settingsEn,
  },
  "uk-UA": {
    common: commonUa,
    validation: validationUa,
    auth: authUa,
    drawings: drawingsUa,
    kids: kidsUa,
    settings: settingsUa
  }
} as const

i18n.use(LanguageDetector).use(initReactI18next).init({
  debug: true,
  fallbackLng: "uk-UA",
  ns: ["common", "auth", "validation", "drawings", "kids", "settings"],
  defaultNS,
  resources,
})

export default i18n
